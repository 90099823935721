<template>
  <div>
    <section
      class="main1"
      style="gap:10px;"
    >
      <FilialeOverview />
    </section>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import FilialeOverview from '../components/Filiales/FilialeOverview.vue'

export default {
  components: {
    FilialeOverview,
  },
  data() {
    return {
    }
  },
  computed: {

  },
  watch: {

  },
  methods: {
    ...mapActions([]),
  },
}
</script>

<style>
.main1{
  padding: 75px 15px 15px 120px;
}

@media screen and (max-width: 1200px) {
  .tables {
    display:flex !important;
    flex-direction: column;
    gap:10px;
    padding-top: 15px;
  }
  .main1{
    padding: 75px 15px 15px 20px;
  }
}

</style>
