<template>
  <div
    @click="removeLists"
  >
    <div style="margin-top: 0px">
      <div>
        <div
          class="filtering"
        >
          <div class="black">
            <b-icon-search
              v-if="searchStore == ''"
              class="search1"
              style="position: absolute; left:5px; top: 12px"
            />
            <b-icon-x-circle
              v-else-if="searchStore != '' && !noSearch"
              class="search1"
              style="position: absolute; right: 20px; top: 12px; cursor: pointer"
              @click="searchStore = ''"
            />
            <input
              v-model="searchStore"
              autocomplete="off"
              type="text"
              name="search-box"
              :placeholder="`${$t('Store')} `"
              :style="getStoresByNameLike.length > 0 && noSearch ? 'border-radius: 8px 8px 0px 0px' : ''"
              @keyup="inputChanged"
              @keydown.down="onArrowDown"
              @keydown.up="onArrowUp"
            >
          </div>
          <div
            v-if="getStoresByNameLike.length > 0 && noSearch"
            ref="scrollContainer"
            class="dropdrop"
            :style="getStoresByNameLike.length > 7 ? 'height: 210px' : 'height: auto'"
          >
            <div
              v-for="(result, index) in getStoresByNameLike"
              :key="index"
              ref="options"
              style="cursor: pointer"
              class="search-item"
              :class="{ 'is-active': index === arrowCounter }"
              @click="searchByOn(result); noSearch = false"
            >
              <p style="margin: 0">
                {{ result }}
              </p>
            </div>
          </div>
        </div>
        <div style="display:flex;gap:5px;">
          <button
            v-b-modal.add-filiale
            class="button"
          >
            {{ $t('AddStore') }}
          </button>
        </div>
        <div
          class="scroll"
          style="margin-top: 10px"
        >

          <table
            class="team_table"
          >
            <thead>
              <tr>
                <th>
                  {{ $t("No") }}
                </th>
                <th>
                  {{ $t("StoreName") }}
                </th>
                <th>
                  {{ $t("LocationNumber") }}
                </th>
                <th>
                  {{ $t("Country") }}
                </th>
                <th>
                  {{ $t("City") }}
                </th>
                <th>
                  {{ $t("Street") }}
                </th>
                <th>
                  {{ $t("DoorNumber") }}
                </th>
                <th>
                  {{ $t("HouseNumber") }}
                </th>
                <th>
                  {{ $t("PhoneNumber") }}
                </th>
                <th>
                  {{ $t("Email") }}
                </th>
                <th>
                  {{ $t("Manager") }}
                </th>
                <th>
                  {{ $t("Actions") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item,index) in getAllStores"
                :key="index"
              >
                <td>
                  {{ getAllStoresTotalItems-(page-1) * pageSize-index }}.
                </td>
                <td>
                  {{ item.storeName }}
                </td>
                <td>
                  {{ item.gln }}
                </td>
                <td>
                  {{ item.country }}
                </td>
                <td>
                  {{ item.city }}
                </td>
                <td>
                  {{ item.street }}
                </td>
                <td>
                  {{ item.door }}
                </td>
                <td>
                  {{ item.house }}
                </td>
                <td>
                  +{{ item.countryCode }} {{ item.phone }}
                </td>
                <td>
                  {{ item.email }}
                </td>
                <td v-if="item.managerId == null || item.managerId.lenth > 0">
                  <button
                    v-b-modal.assign-manager
                    class="editButton"
                    @click="storeId = item.storeId"
                  >
                    {{ $t('Assign') }}
                  </button>
                </td>
                <td v-else>
                  {{ item.managerName }}
                </td>
                <td
                  @click="storeDetails(item), editS(item.storeId)"
                >
                  <button
                    v-b-modal.edit-filiale
                    class="editButton"
                  >
                    {{ $t('Edit') }}
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-if="getAllStoresTotalItems > 15">
          <b-pagination
            v-model="page"
            :total-rows="getAllStoresTotalItems"
            :per-page="pageSize"
            first-number
            last-number
            align="fill"
            prev-class="prev-itemi"
            next-class="next-itemi"
            class="mt-1 mb-0"
          >
            <template #prev-text>
              <span class="previousOrNext"><b-icon-arrow-left />{{ $t('Previous') }}</span>
            </template>
            <template #next-text>
              <span class="previousOrNext">{{ $t('Next') }}<b-icon-arrow-right /></span>
            </template>
          </b-pagination>
        </div>
      </div>
    </div>
    <AddFilialeModal
      @filiale="addFiliale"
    />
    <EditFilialeModal
      :edit-store-details="editStoreDetails"
      @edit="editStoreById"
    />
    <AssignManager
      :store-id="storeId"
      @assignManager="assignManager"
    />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { client } from '@/domainConfig'
import AddFilialeModal from './AddFilialeModal.vue'
import EditFilialeModal from './EditFilialeModal.vue'
import AssignManager from './AssignManager.vue'

export default {
  components: {
    AddFilialeModal,
    EditFilialeModal,
    AssignManager,
  },
  data() {
    return {
      client: client.clientName,
      editStoreDetails: {},
      page: 1,
      pageSize: 15,
      arrowCounter: 0,
      enterPressed: false,
      storeId: null,
      searchStore: '',
      noSearch: false,
      storeName: null,
      editStore: {
        storeId: null,
        name: '',
        gln: '',
        countryCode: 'XK',
        phone: '',
        email: '',
        street: '',
        houseNumber: '',
        doorNumber: '',
        postalCode: '',
        city: '',
        country: 'Kosova',
        countryId: null,
        countryStateId: null,
        countryState: 'Kosova',
        managerId: null,
        teamLeaderId: null,
        coTeamLeaderId: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getAllStores',
      'getAllStoresTotalPages',
      'getAllStoresTotalItems',
      'getStoresByNameLike',
    ]),
  },
  watch: {
    searchStore(value) {
      if (this.timeoutId) clearTimeout(this.timeoutId);

      this.timeoutId = setTimeout(() => {
        if (value === '') {
          this.removeLists();
          this.storesByPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
            storeName: this.searchStore,
          });
        } else if (!this.enterPressed) {
          this.storeByNameLike({ storeName: value });
          this.noSearch = true;
        }
      }, 500);
    },
    page(newValue) {
      this.storesByPagination({
        pageNumber: newValue,
        pageSize: this.pageSize,
        storeName: this.storeName,
      });
    },
  },
  mounted() {
    this.storesByPagination({ pageNumber: this.page, pageSize: this.pageSize, storeName: this.storeName })
    console.log('Stores:', this.getAllStores);
  },
  methods: {
    ...mapActions([
      'storesByPagination',
      'addStore',
      'resetFilteredMVBySearch',
      'editStores',
      'assignEmployeeToStore',
      'storeByNameLike',
      'resetStores',
    ]),
    onArrowDown(ev) {
      ev.preventDefault()
      if (this.arrowCounter < this.getStoresByNameLike.length - 1) {
        this.arrowCounter += 1;
        this.fixScrolling();
      }
    },
    onArrowUp(ev) {
      ev.preventDefault()
      if (this.arrowCounter > 0) {
        this.arrowCounter -= 1;
        this.fixScrolling()
      }
    },
    inputChanged(event) {
      if (event.code == 'ArrowUp' || event.code == 'ArrowDown') { return; }
      // this.getFilteredOrdersbyON = [];

      // eslint-disable-next-line no-useless-return
      if (event.code == 'Enter') {
        this.enterPressed = true
        this.searchByOn(this.getStoresByNameLike[this.arrowCounter])
        this.removeLists()
        this.arrowCounter = 0
        setTimeout(() => {
          this.enterPressed = false
        }, 1000)
        // eslint-disable-next-line no-useless-return
        return;
      }
    },
    fixScrolling() {
      const liH = this.$refs.options[this.arrowCounter].clientHeight;
      console.log(liH)
      this.$refs.scrollContainer.scrollTop = liH * this.arrowCounter;
    },
    async searchByOn(value) {
      this.searchStore = value
      this.storeName = value;
      this.noSearch = false
      await this.storesByPagination({
        storeName: this.storeName,
        pageNumber: this.page,
        pageSize: this.pageSize,
        successCallback: () => {
          // this.$router.push('/order-item-tracking')
        },
      })
      // this.checkIfArticleNumWasNull()
    },
    removeLists() {
      this.noSearch = false;
      this.resetStores();
    },
    addFiliale(value) {
      this.addStore({
        object: value,
        successCallback: () => {
          this.storesByPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
            storeName: this.storeName,
          })
        },
      })
    },
    storeDetails(value) {
      console.log('edit', value)
      this.editStoreDetails = value
    },
    editS(value) {
      this.storeId = value
    },
    async editStoreById(obj) {
      console.log(this)
      await this.editStores({
        storeId: this.storeId,
        object: obj,
        successCallback: () => {
          this.storesByPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
            storeName: this.storeName,
          });
        },
      });
    },
    assignManager(value) {
      this.assignEmployeeToStore({
        object: {
          isManager: true,
          isTeamLeader: false,
          isCoTeamLeader: false,
          storeId: this.storeId,
          employeeUserId: value.userId,
        },
        successCallback: () => {
          this.storesByPagination({
            pageNumber: this.page,
            pageSize: this.pageSize,
            storeName: this.storeName,
          });
        },
      })
    },
  },
};
</script>

<style scoped lang="scss">

.team_table td {
  padding: 11px 9px;
}
@media screen and (max-width: 1200px) {
  .scroll {
    overflow-x: scroll;
  }
}

table:hover {
  cursor: pointer;
}

.dropdrop::-webkit-scrollbar {
  display: none;

}

.dropdrop {
  display: inline-block;
  overflow: auto;
  position: absolute;
  background: white;
  width: 227px;
  box-shadow: none;
  top: 43px ;
}

input[name="search-box"] {
  display: block;
  max-width: 500px;
  width: 95%;
  padding: 0.5rem 1rem;
  padding-left: 25px;
}

.search-item {
  padding: 5px;
  max-width: 500px;
  width: 100%;
  font-weight: 400;
  color: #82868c;
  font-size: 1rem;
  border-bottom: 1px solid #e8e8e8;
}

.search-item:last-child {
  border-bottom: none;
}

.search-item:hover {
  background: #82868c;
  color: white;
}

.search-item hr {
  color: lightgray;
  border-top: none;
  margin: -1.3rem 0 0.5rem 0;
}

.is-active {
  background-color: #dedede;
}

@media screen and (max-width: 1190px) {
  .main {
    padding: 80px 10px 15px 10px !important;
  }
}
@media screen and (max-width: 1340px){
  .main{
    padding: 80px 15px 15px 90px;
  }
}
@media screen and (max-width: 500px){
  .main{
    padding: 60px 15px 15px 10px;
  }
}

.is-active {
  background-color: #dedede;
}
</style>
