<template>
  <div>
    <b-modal
      id="assign-manager"
      ref="modal"
      header-class="headerModal"
      centered
      :title="$t('Assign manager')"
      size="md"
      hide-footer
      @close="onCancel"
    >
      <b-form>
        <b-form-group
          id="input-group-2"
          :label="$t('Manager')"
          label-for="input-2"
        >
          <vue-select
            id="input-12"
            v-model="form.userId"
            required
            :options="getUsersByRole"
            label="fullName"
            :reduce="(e) => e.userID"
            :placeholder="$t('Select')"
            aria-describedby="input-1-live-feedback"
          />
        </b-form-group>
      </b-form>
      <div class="buttonsEverywhere">
        <b-button
          type="button"
          variant="none"
          class="buttonSubmit"
          @click="onSubmit"
        >
          {{ $t("Submit") }}
        </b-button>
        <b-button
          type="button"
          variant="danger"
          class="buttonCancel"
          style="margin-right: 15px"
          @click="onCancel"
        >
          {{ $t("Cancel") }}
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  components: {},
  props: ['storeId'],
  data() {
    return {
      form: {
        userId: null,
      },
    };
  },
  computed: {
    ...mapGetters(['getUsersByRole']),

  },

  watch: {

  },
  mounted() {
    this.usersByRole({ personRole: 'Manager' })
  },
  methods: {
    ...mapActions(['usersByRole']),
    onSubmit() {
      const form = {
        storeId: this.storeId,
        userId: this.form.userId,
      }
      this.$emit('assignManager', form);
      this.$refs.modal.hide();
      this.onReset();
    },
    onCancel() {
      this.$refs.modal.hide();
      this.onReset();
    },
    onReset() {
    },
  },
};
</script>

<style lang="scss" scoped>
.d-flex{
  display: flex;
  gap:10px;
}
.form-group{
  width:100%
}
</style>
